import DragNDrop from '../components/DragNDrop';
import '../styles/FileUpload.scss';
import { useParams, useNavigate } from 'react-router-dom';
import FileList from '../components/FileList';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import UploadButton from '../components/buttons/UploadButton';
import DownloadButton from '../components/buttons/DownloadButton';
import DeleteDocumentButton from '../components/buttons/DeleteDocumentButton';
import { motion } from 'framer-motion';
import { useInboxName } from '../hooks/inboxHooks';
import Loader from '../components/Loader';
import { getAllDocuments } from '../features/files/fileSlice';
import { isEqual } from 'lodash';
import { getEmailAddresses, getInboxInfo, resetUpdateInfo } from '../features/inboxes/inboxSlice';
import { useTranslation } from 'react-i18next';
import { RiSettings4Line } from "react-icons/ri";
import { LuClipboardCopy } from "react-icons/lu";
import { toast } from 'react-toastify';
import { handleFileUpload } from '../uploadUtils';
import DragNDropInfo from '../components/DragNDropInfo';



export default function FileUpload() {

    const { inboxId } = useParams();
    const { inboxData, newInboxID, isInfoUpdated, emailAddress } = useSelector(state => state.inbox);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const inboxName = useInboxName(inboxId, inboxData);
    const { documentsData, isLoadingDocuments, offset, isDocumentDeleted } = useSelector(state => state.documents);
    const dispatch = useDispatch();
    const [shouldFetchDocuments, setShouldFetchDocuments] = useState(true);
    const [prevInboxData, setPrevInboxData] = useState([]);
    const [showDragNDrop, setShowDragNDrop] = useState(false);
    const dragNDropTimeoutRef = useRef(null);
    const [newInboxName, setNewInboxName] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);


    useEffect(() => {
        if (!isLoadingDocuments && documentsData.length === 0) {
            dragNDropTimeoutRef.current = setTimeout(() => {
                setShowDragNDrop(true);
            }, 400);
        } else {
            setShowDragNDrop(false);
            if (dragNDropTimeoutRef.current) {
                clearTimeout(dragNDropTimeoutRef.current);
                dragNDropTimeoutRef.current = null;
            }
        }
    }, [isLoadingDocuments, documentsData.length]);


    useEffect(() => {
        if (!isEqual(inboxData, prevInboxData)) {
            setPrevInboxData(inboxData);
            if (inboxData?.length > 0) {
                setShouldFetchDocuments(true);
            }
        }
    }, [inboxData, prevInboxData]);


    useEffect(() => {
        if (shouldFetchDocuments && inboxData?.length > 0 && !isLoadingDocuments) {
            inboxData.forEach(inbox => {
                if ((inbox.document_count > 0 || inbox.id === newInboxID) && inbox.id === inboxId) {
                    dispatch(getAllDocuments({ inboxID: inbox.id, offset: offset }));
                }
            })
            setShouldFetchDocuments(false);

        }
    }, [shouldFetchDocuments, inboxData, isLoadingDocuments, dispatch, inboxId, newInboxID, offset]);


    useEffect(() => {
        if (inboxName) {
            setNewInboxName(inboxName);
        }
    }, [inboxName]);



    useEffect(() => {
        if (isInfoUpdated) {
            dispatch(getInboxInfo());
            dispatch(resetUpdateInfo())
        }
    }, [isInfoUpdated, dispatch, inboxId, navigate]);


    useEffect(() => {
        if (isDocumentDeleted) {
            setSelectedFiles([]);
            setSelectAll(false);
            setDisabledButton(true);
            dispatch(getAllDocuments({ inboxID: inboxId, offset: offset }));
            dispatch(getInboxInfo({ limit: 1000 }));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [isDocumentDeleted]);

    useEffect(() => {
        if (inboxId) {
            setSelectAll(false);
            setSelectedFiles([]);
            dispatch(getEmailAddresses(inboxId));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [inboxId]);

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            if (text.includes('@')) {
                toast.success(t('emailCopied', { ns: 'translation' }))
            } else {
                toast.success(t('IDCopied'))
            }
        } catch (err) {
            toast.error(t('copyToClipboardError', { ns: 'errors' }))
        }
    }


    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        if (inboxId && selectedFiles && selectedFiles.length > 0) {
            handleFileUpload(selectedFiles, inboxId, dispatch, t, offset);
        }
    };


    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles && droppedFiles.length > 0) {
            handleFileUpload(droppedFiles, inboxId, dispatch, t, offset);
            setIsDragging(false);
        }

    };

    const handleDrag = (e) => {
        e.preventDefault();
        setIsDragging(true);
    }


    return (
        <>
            <main className='file-upload-container'
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDrag}
                onDragLeave={() => setIsDragging(false)}
                style={{ opacity: isDragging ? '0.5' : '1' }}
            >
                {newInboxName &&
                    (
                        <>
                            <div className="title-actions-wrapper">
                                <motion.div
                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0 }}
                                    transition={{ duration: .9, delay: .1 }}
                                    className="title-container">
                                    <div className='title-icon-container'>
                                        <h3 className="upload-title">{newInboxName}</h3>
                                        <div className='icon-container settings-icon-container'>
                                            <RiSettings4Line className='settings-icon icon' title={t('inboxSettings')} onClick={() => navigate(`/inboxes/${inboxId}/settings`)} />
                                        </div>
                                    </div>
                                    {emailAddress && <div className='email-icon-container'>
                                        <a className="mailbox-title" href={`mailto:${emailAddress}`}><h4 className="mailbox-title" >{emailAddress}</h4> </a>
                                        <div className='icon-container settings-icon-container'>
                                            <LuClipboardCopy className='copy-icon icon' onClick={() => copyToClipboard(emailAddress)} title='Copy to clipboard' />
                                        </div>
                                    </div>}
                                </motion.div>

                                <motion.div
                                    animate={{ x: 0, opacity: 1 }}
                                    initial={{ x: 400, opacity: 0 }}
                                    transition={{ duration: .9 }}
                                    className='button-container'>
                                    {documentsData.length > 0 && (
                                        <>
                                            <UploadButton handleFileChange={handleFileChange} />
                                            <DownloadButton selectedFiles={selectedFiles} disabledButton={disabledButton} selectAll={selectAll} />
                                            <DeleteDocumentButton selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} disabledButton={disabledButton} />
                                        </>
                                    )}
                                </motion.div>
                            </div>
                            {isLoadingDocuments ? <Loader /> :
                                (documentsData.length > 0 ?
                                    <>
                                        <FileList setSelectedFiles={setSelectedFiles} selectAll={selectAll} setSelectAll={setSelectAll} selectedFiles={selectedFiles} setDisabledButton={setDisabledButton} />
                                    </>
                                    :
                                    (showDragNDrop &&
                                        <div className="import-container">
                                            <div className="import-header">
                                                <h1 className="import-title">{t('importFirst')}</h1>
                                                <p className="import-subtitle">{t('sendDocument')}</p>

                                            </div>
                                            <DragNDrop handleFileChange={handleFileChange} handleDrop={handleDrop} />
                                            <p className="import-subtitle">{t('importSubtitle')}</p>
                                        </div>
                                    )
                                )
                            }
                        </>
                    )
                }
            </main>
            {isDragging && <DragNDropInfo handleDrag={handleDrag} setIsDragging={setIsDragging} />}
        </>
    )
}
