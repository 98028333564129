import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createEmailAddress, fetchAllInboxInfo, fetchEmailAddresses, fetchInboxIds, fetchInboxInfo, patchInboxInfo, postNewInbox } from "./inboxService";
import { toast } from "react-toastify";
import { t } from "i18next";
import { inboxLimit } from "../../config";
import { changeResourcePermissions, deleteResources, fetchResourcePermission } from "../resources/resourcesService";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isErrorInbox: false,
    messageInbox: '',
    inboxData: null,
    newInboxID: '',
    isInfoUpdated: false,
    isUpdatingInfo: false,
    isInboxAdded: false,
    inboxesCount: 0,
    pipelineId: null,
    inboxStatus: 'idle',
    inboxPermissions: null,
    isInboxDeleted: false,
    inboxDetails: [],
    emailAddress: '',
    emailAddresses: [],
    isInboxDeletedForOwner: false
}

export const getInboxesCount = createAsyncThunk('inboxes/getInboxesCount', async ({ limit = inboxLimit, offset = 0 } = {}, thunkAPI) => {
    try {
        const response = await fetchInboxIds(limit, offset);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message;
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const getInboxInfo = createAsyncThunk('inboxes/getInboxInfo', async ({ limit = inboxLimit, offset = 0 } = {}, thunkAPI) => {
    try {
        const response = await fetchAllInboxInfo(limit, offset);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message;
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const addNewInbox = createAsyncThunk('inboxes/addNewInbox', async (newInbox, thunkAPI) => {
    try {
        const response = await postNewInbox(newInbox);
        return response;
    } catch (error) {
        toast.error(t('inboxCreationError', { ns: 'errors' }), {
            toastId: 'inboxCreationError1',
        })
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const editInboxInfo = createAsyncThunk('inboxes/editInboxInfo', async ({ inboxId, newInbox }, thunkAPI) => {
    try {
        const response = await patchInboxInfo(inboxId, newInbox);
        return response;
    } catch (error) {
        toast.error(t('inboxNameError', { ns: 'errors' }), {
            toastId: 'inboxNameError1',
        })
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const getInboxDetails = createAsyncThunk('inboxes/getInboxDetails', async (inboxId, thunkAPI) => {
    try {
        const response = await fetchInboxInfo(inboxId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})


export const getInboxPermissions = createAsyncThunk('inboxes/getInboxPermissions', async (inboxId, thunkAPI) => {
    try {
        const response = await fetchResourcePermission(inboxId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const deleteInboxForOwnerOnly = createAsyncThunk('inboxes/changeInboxPermissions', async ({ inboxId, newPermissions }, thunkAPI) => {
    try {
        const response = await changeResourcePermissions(inboxId, newPermissions);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const createEmail = createAsyncThunk('inboxes/createEmailAddress', async (inboxId, thunkAPI) => {
    try {
        const response = await createEmailAddress(inboxId);

        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }

})

export const getEmailAddresses = createAsyncThunk('inboxes/getEmailAddresses', async (inboxId, thunkAPI) => {
    try {
        const response = await fetchEmailAddresses(inboxId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const deleteInbox = createAsyncThunk('inboxes/deleteInbox', async (inboxId, thunkAPI) => {
    try {
        const response = await deleteResources(inboxId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageInbox = error.response.data.detail
            return thunkAPI.rejectWithValue(messageInbox);
        } else {
            const messageInbox = error.message
            return thunkAPI.rejectWithValue(messageInbox);
        }
    }
})

export const inboxSlice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {
        resetInbox: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isErrorInbox = false;
            state.messageInbox = '';
            state.inboxData = null;
            state.newInboxID = '';
            state.isInfoUpdated = false;
            state.isInboxAdded = false;
            state.isInboxDeleted = false;
            state.isInboxDeletedForOwner = false;
        },
        resetUpdateInfo: (state) => {
            state.isInfoUpdated = false;
        },
        resetNewInboxID: (state) => {
            state.isInboxAdded = false;
            state.newInboxID = '';
        },
        createInboxData: (state) => {
            state.inboxData = [];
        },
        resetPipelineId: (state) => {
            state.pipelineId = null;
        },
        resetEmails: (state) => {
            state.emailAddresses = [];
            state.inboxStatus = 'idle';
            state.emailAddress = '';
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(getInboxInfo.pending, (state) => {
                state.isLoading = true;
                state.isInboxAdded = false;
            })
            .addCase(getInboxInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.inboxData = action.payload;
                state.isInboxAdded = false;
                state.isInboxDeleted = false;
            })
            .addCase(getInboxInfo.rejected, (state, action) => {
                state.isLoading = false;
                state.isErrorInbox = true;
                state.messageInbox = action.payload;
            })
            .addCase(addNewInbox.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addNewInbox.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isInboxAdded = true;
                state.inboxData = action.payload;
                state.newInboxID = action.payload.id
            })
            .addCase(addNewInbox.rejected, (state, action) => {
                state.isLoading = false;
                state.isErrorInbox = true;
                state.messageInbox = action.payload;
            })
            .addCase(editInboxInfo.pending, (state) => {
                state.isUpdatingInfo = true;
            })
            .addCase(editInboxInfo.fulfilled, (state) => {
                state.isUpdatingInfo = false;
                state.isSuccess = true;
                state.isInfoUpdated = true;
            })
            .addCase(editInboxInfo.rejected, (state, action) => {
                state.isUpdatingInfo = false;
                state.isErrorInbox = true;
                state.messageInbox = action.payload;
                state.isInfoUpdated = false;
            })
            .addCase(getInboxesCount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getInboxesCount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.inboxesCount = action.payload.count;
            })
            .addCase(getInboxesCount.rejected, (state, action) => {
                state.isLoading = false;
                state.isErrorInbox = true;
                state.messageInbox = action.payload;
            })
            .addCase(getInboxDetails.pending, (state) => {
                state.inboxStatus = 'loading';
            })
            .addCase(getInboxDetails.fulfilled, (state, action) => {
                state.inboxStatus = 'success';
                state.pipelineId = action.payload.pipeline_id;
                state.inboxDetails = action.payload;
            })
            .addCase(getInboxDetails.rejected, (state, action) => {
                state.inboxStatus = 'error';
                state.messageInbox = action.payload;
            })
            .addCase(getInboxPermissions.pending, (state) => {
                state.inboxStatus = 'loading';
            })
            .addCase(getInboxPermissions.fulfilled, (state, action) => {
                state.inboxStatus = 'success';
                state.inboxPermissions = action.payload;
            })
            .addCase(getInboxPermissions.rejected, (state, action) => {
                state.inboxStatus = 'error';
                state.messageInbox = action.payload;
            })
            .addCase(deleteInbox.pending, (state) => {
                state.inboxStatus = 'loading';
            })
            .addCase(deleteInbox.fulfilled, (state) => {
                state.inboxStatus = 'success';
                state.isInboxDeleted = true;
            })
            .addCase(deleteInbox.rejected, (state, action) => {
                state.inboxStatus = 'error deleting inbox';
                state.messageInbox = action.payload;
            })
            .addCase(createEmail.pending, (state) => {
                state.inboxStatus = 'loading';
            })
            .addCase(createEmail.fulfilled, (state, action) => {
                state.inboxStatus = 'success';
                state.emailAddress = action.payload.email;
            })
            .addCase(createEmail.rejected, (state, action) => {
                state.inboxStatus = 'error creating email';
                state.messageInbox = action.payload;
            })
            .addCase(getEmailAddresses.pending, (state) => {
                state.inboxStatus = 'loading';
            })
            .addCase(getEmailAddresses.fulfilled, (state, action) => {
                state.inboxStatus = 'success';
                state.emailAddress = action.payload.results[0].email;
                state.emailAddresses = action.payload.results.map((e) => e.email);
            })
            .addCase(getEmailAddresses.rejected, (state, action) => {
                state.inboxStatus = 'error fetching emails';
                state.messageInbox = action.payload;
            })
            .addCase(deleteInboxForOwnerOnly.pending, (state) => {
                state.inboxStatus = 'loading';
            })
            .addCase(deleteInboxForOwnerOnly.fulfilled, (state) => {
                state.inboxStatus = 'success';
                state.isInboxDeletedForOwner = true;
            })
            .addCase(deleteInboxForOwnerOnly.rejected, (state, action) => {
                state.inboxStatus = 'error deleting inbox';
                state.messageInbox = action.payload;
            })
    }
})


export const {
    resetInbox,
    resetUpdateInfo,
    resetNewInboxID,
    createInboxData,
    resetPipelineId,
    resetEmails
} = inboxSlice.actions;

export default inboxSlice.reducer;
