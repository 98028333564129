import React, { useEffect, useRef, useState } from 'react'
import ColorHash from 'color-hash'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../styles/SVGContainer.scss'
import { CgZoomIn } from "react-icons/cg";
import { CgZoomOut } from "react-icons/cg";
import { TbZoomReset } from "react-icons/tb";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { motion } from 'framer-motion';
import { MdOutlineRotateRight } from "react-icons/md";
import { useTranslation, Trans } from 'react-i18next';
import { getDocumentPreview, hideBoundingBoxes, resetDocumentPreview, showBoundingBoxes } from '../features/files/fileSlice';
import Loader from './Loader';
import { minImgHeight, minImgWidth, maxImgHeight, maxImgWidth, highlightMinLength } from '../config';


const colorHash = new ColorHash();

export default function SVGContainer({
    borderHighlightStyles,
    isHovered,
    svgHeight,
    svgWidth,
    setSvgWidth,
    setSvgHeight,
    showData,
    showNoCreditError,
    validatedData,
    page
}) {

    const {
        documentPreview,
        isLoadingDocumentPreview,
        isErrorDocumentPreview,
        isLoadingDocumentExtraction,
        isErrorDocumentExtraction,
        fullDocumentExtraction,
        boundingBoxesVisible,
        thumbnailsVisible,
        currentPreviewPage } = useSelector(state => state.documents);

    const { documentId } = useParams();
    const svgRef = useRef();
    const [rotation, setRotation] = useState(0);
    const [highlightedBoxStyle, setHighlightedBoxStyle] = useState([]);
    const { t } = useTranslation('translation', 'errors');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetDocumentPreview())
        dispatch(getDocumentPreview({ docId: documentId, page: 0 }))
    }, [dispatch, documentId]);


    useEffect(() => {
        if (documentPreview) {
            const img = new Image();
            img.onload = function () {
                const width = Math.max(10, this.width)
                const height = Math.max(10, this.height)
                const xFactor =
                    (width < minImgWidth) ? minImgWidth / width
                        : (width > maxImgWidth) ? maxImgWidth / width
                            : 1
                const yFactor =
                    (height < minImgHeight) ? minImgHeight / height
                        : (height > maxImgHeight) ? maxImgHeight / height
                            : 1
                // Ensure at least one size is at least as big as the
                // minimum and both sides are not larger than the
                // maximum image dimensions.
                const factor = Math.min(xFactor, yFactor)
                setSvgWidth(Math.round(factor * width));
                setSvgHeight(Math.round(factor * height));
            }
            img.src = documentPreview;
        }
    }, [documentPreview, setSvgWidth, setSvgHeight, svgHeight, svgWidth]);


    useEffect(() => {
        dispatch(hideBoundingBoxes());
        setHighlightedBoxStyle([]);
        if (!validatedData || !documentPreview || !fullDocumentExtraction) return;

        const collectBoxes = (item) => {
            const { type, value } = item;
            if (type === "text" && (typeof value === 'string' || typeof value === 'number') && value.toString().length >= highlightMinLength) {
                return page ? page.findMatchingBoxes(value) : [];
            }
            if (type === "record") {
                return value.flatMap(collectBoxes);
            }
            return [];
        };

        const newHighlights = validatedData.flatMap(item => {
            const rgbColor = colorHash.rgb(item.label);

            if (item.box && item.page === currentPreviewPage) {
                const [left, top, right, bottom] = item.box;
                return [{
                    x: `${left * svgWidth - 1}`,
                    y: `${top * svgHeight - 1}`,
                    height: `${(bottom - top) * svgHeight + 2}`,
                    width: `${(right - left) * svgWidth + 2}`,
                    stroke: `rgb(${rgbColor})`,
                    strokeWidth: 1.5,
                    fill: 'none',
                }];
            }

            return item.value.flatMap(subItem => {
                if (subItem.box && subItem.page === currentPreviewPage) {
                    const [left, top, right, bottom] = subItem.box;
                    return {
                        x: `${left * svgWidth - 1}`,
                        y: `${top * svgHeight - 1}`,
                        height: `${(bottom - top) * svgHeight + 2}`,
                        width: `${(right - left) * svgWidth + 2}`,
                        stroke: `rgb(${rgbColor})`,
                        strokeWidth: 1.5,
                        fill: 'none',
                    };
                }
                else {
                    if (!collectBoxes) {
                        return [];
                    }
                    const boxes = (collectBoxes(subItem) || []).filter(Boolean);
                    return boxes.map(([left, top, right, bottom]) => ({
                        x: `${left * svgWidth - 1}`,
                        y: `${top * svgHeight - 1}`,
                        height: `${(bottom - top) * svgHeight + 2}`,
                        width: `${(right - left) * svgWidth + 2}`,
                        stroke: `rgb(${rgbColor})`,
                        strokeWidth: 1.5,
                        fill: 'none',
                    }));
                }
            });
        });

        setHighlightedBoxStyle(newHighlights);
        dispatch(showBoundingBoxes());
    }, [documentPreview, setSvgWidth, setSvgHeight, svgHeight, svgWidth, validatedData, fullDocumentExtraction, currentPreviewPage, page]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div className='preview-container'>
                {isLoadingDocumentPreview && !showData && !showNoCreditError || !thumbnailsVisible ? <Loader /> :
                    (documentPreview && !showNoCreditError && thumbnailsVisible && (
                        <>
                            <TransformWrapper
                                initialScale={1}
                                limitToBounds={false}
                                minScale={0.2}
                                maxScale={4}
                                wheel={{ activationKeys: ["Control"] }}
                            // centerContent={true}
                            >
                                {({ zoomIn, zoomOut, resetTransform }) => (
                                    <div className="tools-preview-container">
                                        <div className="zoom-tools">
                                            <div className="tooltip-wrapper">
                                                <CgZoomIn onClick={() => zoomIn(0.2)} className="edit-tool zoom-in zoom-tool" />
                                                <div className="tooltip-text">{t('zoomIn')}</div>
                                            </div>
                                            <div className="tooltip-wrapper">
                                                <CgZoomOut onClick={() => zoomOut(0.2)} className="edit-tool zoom-out zoom-tool" />
                                                <div className="tooltip-text">{t('zoomOut')}</div>
                                            </div>
                                            <div className="tooltip-wrapper">
                                                <TbZoomReset onClick={() => resetTransform()} className="edit-tool reset-tool" />
                                                <div className="tooltip-text">{t('resetSize')}</div>
                                            </div>
                                            <div className="tooltip-wrapper">
                                                <MdOutlineRotateRight onClick={() => setRotation(rotation + 90)} className="edit-tool rotate-tool" />
                                                <div className="tooltip-text">{t('rotate')}</div>
                                            </div>
                                        </div>
                                        <div
                                            className='wrapper-bkg'>

                                            <TransformComponent >
                                                <motion.div
                                                    animate={{ rotate: rotation }} className='svg-wrapper'>
                                                    <svg
                                                        ref={svgRef}
                                                        style={{ width: svgWidth, height: svgHeight }}
                                                    >
                                                        {!showNoCreditError && <image
                                                            href={documentPreview}
                                                            // alt={file.name}
                                                            width='100%'
                                                            height='100%'
                                                        />}
                                                        {!isLoadingDocumentExtraction && !isErrorDocumentExtraction && boundingBoxesVisible && highlightedBoxStyle.map((style, idx) => (
                                                            <rect
                                                                className="highlighted-box"
                                                                {...style}
                                                                key={idx}
                                                            />
                                                        ))}
                                                        {borderHighlightStyles.map((style, index) => (
                                                            <rect
                                                                key={index}
                                                                className="border-on-box"
                                                                x={style.x}
                                                                y={style.y}
                                                                width={style.width}
                                                                height={style.height}
                                                                fill={isHovered ? 'rgba(27, 120, 242, 0.2)' : 'none'}
                                                            />
                                                        ))}
                                                    </svg>
                                                </motion.div>
                                            </TransformComponent>
                                        </div>
                                    </div>
                                )}
                            </TransformWrapper>
                        </>
                    ))
                }
            </div>
            {!documentPreview && isErrorDocumentPreview && <p className='no-preview'>{t('noPreview', { ns: 'errors' })}</p>}
            {showNoCreditError && <>
                <div className='no-credit-error'>
                    <p className='no-credit-error-msg'>{t('insufficientCredit', { ns: 'errors' })}</p>
                    <p className='no-credit-error-msg'>{t('continueTransfer')}</p>
                    <div className='no-credit-error-msg'>
                        <Trans t={t} i18nKey='contactForSupport' ns='translation' className='no-credit-error'> <a href={`mailto:${t('emailAddress')}`}>Contact us </a> to top up your credit and keep enjoying seamless data transfer.</Trans>
                    </div>
                </div>
            </>}
        </>
    )
}
