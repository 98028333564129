import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/TableList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { deleteKey, getUserAPIKeys, resetKeyStatus } from '../features/user/userSlice';
import Cookies from 'js-cookie';
import { RiDeleteBin6Line } from "react-icons/ri";
import { getLocalDate, getLocalDateTime } from '../config';


export default function APIKeysList() {
    const { t } = useTranslation("userManagement");
    const dispatch = useDispatch();
    const { APIKeys, keyStatus } = useSelector(state => state.user);
    const [noKeys, setNoKeys] = useState(false);
    const language = Cookies.get('language')
    const [newKeyAdded, setNewKeyAdded] = useState(false);

    useEffect(() => {
        dispatch(getUserAPIKeys())
    }, [dispatch])

    useEffect(() => {
        if (APIKeys) {
            if (APIKeys.count === 0) {
                setNoKeys(true);
            }
        }
    }, [APIKeys])

    const handleDelete = (keyPrefix) => {
        dispatch(deleteKey(keyPrefix))
    }

    useEffect(() => {
        if (keyStatus === 'deleted' || newKeyAdded) {
            dispatch(getUserAPIKeys())
            dispatch(resetKeyStatus())
        }
    }, [keyStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (keyStatus === 'added') {
            setNoKeys(false)
            setNewKeyAdded(true)
            dispatch(resetKeyStatus())
        }
    }, [keyStatus])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="list-main-container list-main-container__api">
            <div className='list-table list-table__api'>
                <div className='list-table-row list-table-row__api'>
                    <div className="list-table-cell list-table-cell__api">{t('APIKeyName')}</div>
                    <div className="list-table-cell list-table-cell__api">{t('APIKeySecret')}</div>
                    <div className="list-table-cell list-table-cell__api">{t('APIKeyCreated')}</div>
                    <div className="list-table-cell list-table-cell__api">{t('APIKeyExpires')}</div>
                    <div className="list-table-cell list-table-cell__api">{t('APIKeyActions')}</div>
                </div>
                {!noKeys && APIKeys.results?.map(key => (
                    <div className='list-table-row list-table-row__api' key={key.prefix}>
                        <div className="list-table-cell list-table-cell__api">{key.name}</div>
                        <div className="list-table-cell list-table-cell__api">{key.prefix}...</div>
                        <div className="list-table-cell list-table-cell__api" title={getLocalDateTime(key.created_at, language)}>
                            {getLocalDate(key.created_at, language)}
                        </div>
                        <div className="list-table-cell list-table-cell__api" title={key.expire_at === null ? null : getLocalDateTime(key.expire_at, language)}>
                            {key.expire_at === null ? t("never") : getLocalDate(key.expire_at, language)}
                        </div>
                        <div className="list-table-cell list-table-cell__api list-icon-container">
                            <RiDeleteBin6Line
                                onClick={() => handleDelete(key.prefix)} className="delete-icon icon"
                                title={t('APIKeyDelete', { ns: 'userManagement' })}
                            />
                        </div>
                    </div>
                ))
                }
            </div>
            {noKeys && <p className='settings-text settings-text__api'>{t('noAPIKeys')}</p>}
        </div>
    )
}
