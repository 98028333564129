import { api } from "../../api"

export const fetchResourcePermission = async (id) => {
    const response = await api.get(`/resources/${id}/permissions`);
    return response.data;
}

export const changeResourcePermissions = async (id, newPermissions) => {
    const response = await api.post(`/resources/${id}/permissions`, newPermissions);
    return response.data;
}


export const deleteResources = async (ids) => {
    const response = await api.delete(`/resources/${ids}`);
    return response.data;
}
