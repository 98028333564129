import { api } from './api';
import { toast } from 'react-toastify';

export const mimeType = {
    csv: "text/csv",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
}

export const downloadCsv = async ({ documentId, documentsData, setShowMenu, t, format = "csv" }) => {
    try {
        const response = await api.get(`/documents/${documentId}/extraction`, {
            headers: {
                Accept: mimeType[format],
            },
            responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        const selectedFile = documentsData.find(doc => doc.id === documentId);
        const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
        link.download = `${fileNameWithoutExtension}.${format}`;
        link.click();
        URL.revokeObjectURL(url);
    } catch (err) {
        toast.error(t('downloadError', { ns: 'errors' }));
    }
    setShowMenu(false);
};
