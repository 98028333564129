import React from 'react'
import { useTranslation } from 'react-i18next';
import '../styles/DangerZoneInbox.scss';
import DeleteInbox from './DeleteInbox';

export default function DangerZoneInbox() {
    const { t } = useTranslation()

    return (
        <div className='danger-zone-container  inbox-item-container' >
            <div className='danger-zone-title-container'>
                <h3 className='danger-zone-title'>{t('dangerZone')}</h3>
                <div className='danger-zone-line'></div>
            </div>
            <div className='danger-zone-item-container'>
                <DeleteInbox />
            </div>
        </div>
    )
}
