import React, { useRef, useEffect } from 'react';
import '../../styles/ArrowDown.scss';
import { FaChevronDown } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { getStatusOptions } from '../../config';

const ArrowDown = ({ selectedOption, menuRef, setNewStatus, showOptions, setShowOptions }) => {
    const containerRef = useRef(null);
    const dropdownRef = useRef(null);
    const location = useLocation();
    const inRightSideMenu = location.pathname.startsWith('documents');
    const statusOptions = getStatusOptions();



    useEffect(() => {
        if (inRightSideMenu && showOptions && menuRef.current && dropdownRef.current) {
            dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [inRightSideMenu, showOptions, menuRef, dropdownRef]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setShowOptions(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    })

    const handleOptionClick = (option) => {
        setNewStatus(option)
        setShowOptions(false);
    };


    return (
        <div className="arrow-container " ref={containerRef}>
            <FaChevronDown className="arrow" onClick={() => setShowOptions(!showOptions)} />
            {showOptions && (
                <div className={inRightSideMenu ? "options-container_right options-container" : "options-container"} >
                    {statusOptions.map((option, index) => (
                        <div
                            key={option}
                            className={`option ${option === selectedOption ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                            ref={index === statusOptions.length - 1 ? dropdownRef : null}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ArrowDown;
