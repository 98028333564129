import Header from '../components/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import { useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar';
import { useLocation } from 'react-router-dom';
import { useMemo, useContext, useEffect } from 'react';
import { ModalContext } from '../contexts/ModalContext';

export default function RootLayout() {

    const { user } = useSelector(state => state.auth);
    const location = useLocation();
    const { inboxData } = useSelector(state => state.inbox);
    const { modalOn, setModalOn } = useContext(ModalContext);



    const sidebar = useMemo(() => {
        if (!user || !inboxData || !location.pathname.startsWith('/inboxes/')) {
            return null;
        }
        return <Sidebar />;
    }, [user, inboxData, location])


    useEffect(() => {
        if (location.pathname.startsWith('/inboxes/')) {
            setModalOn(false);
        }
    }, [location, setModalOn])


    return (
        <>
            <div className={modalOn ? 'content-area content-area__modal-active' : 'content-area'}>
                {sidebar}
                <div className={location.pathname.startsWith('/inboxes/') ? 'main-area main-area-filelist' : 'main-area'}>
                    <Header />
                    <Outlet />
                </div>
            </div>
            {!location.pathname.startsWith('/documents/') && !location.pathname.includes('/add-inbox/') && !location.pathname.includes('/demo/') && <Footer modalOn={modalOn} />}
        </>

    )
}
