import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../styles/RightSideMenu.scss'
import ValidationInput from './ValidationInput';
import ArrowDown from './ArrowDown';
import { getDocumentData, getDocumentExtraction, getDocumentStatus, resetDocumentExtraction } from '../features/files/fileSlice';
import WorkingLoader from './WorkingLoader';
import { useTranslation } from 'react-i18next';
import { LuClipboardCopy } from "react-icons/lu";
import { toast } from 'react-toastify';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Collapsible from 'react-collapsible';
import { resetPipelineId } from '../features/inboxes/inboxSlice';
import DotsOptionsRXMenu from './buttons/DotsOptionsRXMenu';
import MoreOptionsRXMenu from './MoreOptionsRXMenu';



export default function RightSideMenu({
    updateBorderStyles,
    setBorderHighlightStyles,
    svgHeight,
    svgWidth,
    setIsHovered,
    showNoCreditError,
    validatedData,
    setValidatedData,
    setIsEditMenuOpen
}) {


    const {
        isLoadingDocumentExtraction,
        documentStatus,
        isErrorDocumentExtraction,
        messageDoc,
        showNoExtraction,
        fullDocumentExtraction,
        currentPreviewPage
    } = useSelector(state => state.documents);

    const { documentId } = useParams();
    const [showDataValidation, setShowDataValidation] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [closeInputField, setCloseInputField] = useState(false);
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    const [newStatus, setNewStatus] = useState('')
    const { t } = useTranslation()
    const [isLoaderOn, setIsLoaderOn] = useState(false)
    const [showJson, setShowJson] = useState(false)
    const [showOptions, setShowOptions] = useState(false);
    const loaderTimeoutRef = useRef(null);
    const [editingSubIndex, setEditingSubIndex] = useState(null);
    const [showMoreOptions, setShowMoreOptions] = useState(false)
    const iconRef = useRef(null);

    const [inputValues, setInputValues] = useState(() => {
        const initialValues = {};
        validatedData.forEach((item, idx) => {
            initialValues[idx] = {};
            if (item.value && Array.isArray(item.value)) {
                item.value.forEach((subItem, subIdx) => {
                    initialValues[idx][subIdx] = subItem.value;
                });
            } else {
                initialValues[idx] = item.value;
            }
        });
        return initialValues;
    });


    useEffect(() => {
        if (isLoadingDocumentExtraction) {
            loaderTimeoutRef.current = setTimeout(() => {
                setIsLoaderOn(true);
            }, 200);
        } else {
            setIsLoaderOn(false);
            if (loaderTimeoutRef.current) {
                clearTimeout(loaderTimeoutRef.current);
                loaderTimeoutRef.current = null;
            }
        }
    }, [isLoadingDocumentExtraction, documentId]);


    const retryExtraction = (documentId) => {
        dispatch(getDocumentExtraction({ docId: documentId }))
        dispatch(getDocumentStatus(documentId))
        dispatch(getDocumentData(documentId))
        dispatch(resetDocumentExtraction())
    }


    useEffect(() => {
        if (documentStatus) {
            setNewStatus(documentStatus)
        }
    }, [documentStatus, newStatus])


    const handleItemClick = (idx, text) => {
        setShowDataValidation(true);
        setEditingIndex(idx);
        setInputValues(prevValues => {
            const newValues = [...prevValues];
            newValues[idx] = text;
            return newValues;
        });
    }


    const handleSubItemClick = (idx, subIdx, text) => {
        setShowDataValidation(true);
        setEditingIndex(idx);
        setEditingSubIndex(subIdx);
        setInputValues(prevValues => {
            const newValues = [...prevValues];
            const newValue = [...newValues[idx]];
            newValue[subIdx] = text;
            newValues[idx] = newValue;
            return newValues;
        });
    };


    useEffect(() => {
        if (showNoExtraction) {
            setShowJson(true)
        } else {
            setShowJson(false)
        }
    }, [showNoExtraction])

    useEffect(() => {
        if (Array.isArray(validatedData)) {
            setInputValues(validatedData.map(() => ''));
        }
        setShowDataValidation(false);
    }, [validatedData]);

    useEffect(() => {
        if (closeInputField) {
            setShowDataValidation(false);
            setCloseInputField(false);
        }
    }, [closeInputField]);

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success(t('copiedToClipboard', { ns: 'userManagement' }))
        } catch (err) {
            toast.error(t('copyToClipboardError', { ns: 'errors' }))
        }
    }


    function calculateBorderBoxes(item) {
        let boxes = item.box ? [item.box] : [];

        if (item.page === currentPreviewPage) {
            const highlightStyles = boxes.map(([left, top, right, bottom]) => {
                return {
                    y: `${top * svgHeight}px`,
                    x: `${left * svgWidth}px`,
                    height: `${(bottom - top) * svgHeight}px`,
                    width: `${(right - left) * svgWidth}px`,
                };
            });

            return highlightStyles;
        }
        return [];
    }


    const handleEditClick = () => {
        setIsEditMenuOpen(true);
        dispatch(resetPipelineId())
    }


    return (
        <>
            {!showNoCreditError && (
                <div className="right-menu-main-container">
                    <div className="extraction-json-container">
                        {!showNoExtraction && <div className='tab-container'>
                            <div className={!showJson ? 'extraction-json-tab active-tab' : 'extraction-json-tab inactive-tab'} onClick={() => setShowJson(false)}>{t('Extraction')}</div>
                            <div className={showJson ? 'underline' : 'active-underline'}></div>
                        </div>}

                        <div className='tab-container'>
                            <div className={showJson ? 'extraction-json-tab active-tab' : 'extraction-json-tab inactive-tab'} onClick={() => setShowJson(true)}>JSON</div>
                            <div className={!showJson ? 'underline' : 'active-underline'}></div>
                        </div>
                        <div ref={iconRef} onClick={() =>
                            setShowMoreOptions(!showMoreOptions)} >
                            <DotsOptionsRXMenu />
                        </div>

                        {showMoreOptions && <MoreOptionsRXMenu setShowMoreOptions={setShowMoreOptions} showMoreOptions={showMoreOptions} iconRef={iconRef} />}
                    </div>
                    <div className='top-padding'></div>
                    <div className="right-menu-container" ref={menuRef}>
                        {isLoaderOn && <WorkingLoader />}

                        <div className="right-menu-header">

                            {!showJson && (
                                <>
                                    {isErrorDocumentExtraction && messageDoc !== 'Not enough credits' &&
                                        <div className="document-status__error__container">
                                            <p className='document-status__error'>{t('extractionError', { ns: 'errors' })}</p>
                                            <button className='btn retry-btn' onClick={() => { retryExtraction(documentId) }}> {t('tryAgain', { ns: 'errors' })}</button>
                                        </div>
                                    }
                                </>
                            )}
                            {!isErrorDocumentExtraction && !isLoaderOn && !showNoExtraction && !isLoadingDocumentExtraction && <button className='edit-fields-btn btn' onClick={() => handleEditClick()}>{t('customizeModel', { ns: 'editFieldMenu' })}</button>}

                        </div>

                        {showJson && (
                            <div className="json-output-container">
                                <LuClipboardCopy className='copy-paste' onClick={() => copyToClipboard(JSON.stringify(validatedData, null, 2))} title='Copy to clipboard' />
                                <pre className='json-output'>{JSON.stringify(fullDocumentExtraction, null, 2)}</pre>
                            </div>
                        )}
                        {fullDocumentExtraction?.entities?.length > 0
                            && !isErrorDocumentExtraction
                            && !showJson
                            && !showNoExtraction
                            && validatedData.map((item, idx) => {
                                const finalLabel = item.label.replace(/_/g, ' ');
                                const borderCoordinates = calculateBorderBoxes(item);
                                return (
                                    <div
                                        className="right-menu-item"
                                        key={idx}
                                        onMouseOver={() => {
                                            if (!Array.isArray(item.value)) {
                                                updateBorderStyles(borderCoordinates)
                                            }
                                        }}
                                        onMouseOut={() => setBorderHighlightStyles([])}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}>
                                        <Collapsible trigger={[finalLabel, <MdOutlineKeyboardArrowRight key={finalLabel} />]} triggerTagName='h3' open={true} transitionTime={200}>
                                            {showDataValidation && editingIndex === idx && item && (item.value || item.value === "") && !Array.isArray(item.value) ?
                                                <ValidationInput
                                                    closeInputField={closeInputField}
                                                    setCloseInputField={setCloseInputField}
                                                    setShowDataValidation={setShowDataValidation}
                                                    inputValue={inputValues[idx]}
                                                    setInputValue={(value) => {
                                                        setInputValues(prevValues => {
                                                            const newValues = [...prevValues];
                                                            newValues[idx] = value;
                                                            return newValues;
                                                        });
                                                    }}
                                                    idx={idx}
                                                    validatedData={validatedData}
                                                    setValidatedData={setValidatedData}
                                                />
                                                : (
                                                    item.value && Array.isArray(item.value) ? (
                                                        item.value.map((subItem, subIdx) => {
                                                            const borderCoordinates = calculateBorderBoxes(subItem);

                                                            return (
                                                                <div
                                                                    key={subIdx}
                                                                    className='right-menu-sub-item'
                                                                    onMouseOver={() => {
                                                                        updateBorderStyles(borderCoordinates)
                                                                    }}
                                                                    onMouseOut={() => setBorderHighlightStyles([])}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleSubItemClick(idx, subIdx, subItem.value);
                                                                    }}>
                                                                    <h4 className="label sub-label">{subItem.label}</h4>
                                                                    {showDataValidation && editingIndex === idx && editingSubIndex === subIdx ? (
                                                                        <ValidationInput
                                                                            closeInputField={closeInputField}
                                                                            setCloseInputField={setCloseInputField}
                                                                            setShowDataValidation={setShowDataValidation}
                                                                            subIdx={subIdx}
                                                                            idx={idx}
                                                                            inputValue={
                                                                                inputValues[idx] &&
                                                                                    inputValues[idx][subIdx] ?
                                                                                    inputValues[idx][subIdx] :
                                                                                    ""}
                                                                            setInputValue={(value) => {
                                                                                setInputValues(prevValues => {
                                                                                    const newValues = [...prevValues];
                                                                                    if (!newValues[idx]) {
                                                                                        newValues[idx] = {};
                                                                                    }
                                                                                    newValues[idx][subIdx] = value;
                                                                                    return newValues;
                                                                                });
                                                                            }}

                                                                            validatedData={validatedData}
                                                                            setValidatedData={setValidatedData}
                                                                            inputValues={inputValues}
                                                                        />
                                                                    ) : (
                                                                        <p className={subItem.value ? 'text' : ' text label-in-text'}>
                                                                            {subItem.value ? subItem.value : 'Enter ' + finalLabel.toLowerCase() + "'s " + subItem.label.toLowerCase()}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            )
                                                        }
                                                        )
                                                    ) : (
                                                        <p className={item.value ? 'text' : ' text label-in-text'} onClick={() => {
                                                            if (!Array.isArray(item.value)) {
                                                                handleItemClick(idx, item.value);
                                                            }
                                                        }}>
                                                            {item.value ? item.value : t('enter') + ' ' + finalLabel.toLowerCase()}
                                                        </p>
                                                    )
                                                )}
                                        </Collapsible>
                                    </div>
                                );
                            })}
                        {fullDocumentExtraction && documentStatus && !isErrorDocumentExtraction && !showJson && !isLoadingDocumentExtraction &&
                            <div className="status-container">
                                <div className="status-box">
                                    <h3>Set status</h3>
                                    <ArrowDown selectedOption={newStatus} documentId={documentId} menuRef={menuRef} setNewStatus={setNewStatus} showOptions={showOptions} setShowOptions={setShowOptions} />
                                </div>
                                <div className="status">
                                    <p className='status-text'> {newStatus}</p>
                                </div>
                            </div>
                        }
                        {!showOptions && (!isErrorDocumentExtraction && !messageDoc) && !isLoadingDocumentExtraction && (
                            <>
                                <div className='separator'></div>
                                <div className="custom-template-msg">
                                    <p className='custom-template-msg-text'  >{t('tailoredSolution')} </p>
                                    <p className='custom-template-msg-text'>
                                        <a className='custom-template-msg-link' href={`mailto:${t('emailAddress')}`}>{t('contactUs')}</a>
                                        {t('personalizedTemplates')}
                                    </p>
                                </div>
                                <div className='separator'></div>
                            </>
                        )}
                    </div>
                </div >
            )
            }
        </>
    )
}


