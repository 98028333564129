import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../styles/TableList.scss'
// import ArrowDown from './ArrowDown';
import { getStatusOptions } from '../config';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentsPagination from './DocumentsPagination';
import Cookies from 'js-cookie';
import { getLocalDateTime } from '../config';



export default function FileList({ selectedFiles, setSelectedFiles, setSelectAll, selectAll, setDisabledButton }) {
    const { documentsData } = useSelector(state => state.documents);
    const { t } = useTranslation();
    const statusOptions = getStatusOptions();
    const language = Cookies.get('language')

    useEffect(() => {
        if (selectAll) {
            const newSelectedFiles = {};
            documentsData.forEach(document => {
                newSelectedFiles[document.id] = true;
            });
            setSelectedFiles(newSelectedFiles);
        } else {
            setSelectedFiles([]);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectAll, documentsData]);


    const handleCheckboxChange = (file, isChecked) => {
        setSelectedFiles(prevFiles => {
            return { ...prevFiles, [file.id]: isChecked };
        })
        if (isChecked) {
            setDisabledButton(false);
        } else {
            const otherSelectedFiles = { ...selectedFiles };
            delete otherSelectedFiles[file.id];
            if (Object.values(otherSelectedFiles).every(value => !value)) {
                setDisabledButton(true);
            }
        }
    }

    const handleSelectAllChange = (isChecked) => {
        setSelectAll(isChecked);
        if (isChecked) {
            setDisabledButton(false);
            setSelectedFiles(documentsData);
            setSelectAll(true);
        } else {
            setSelectedFiles([]);
            setDisabledButton(true);
        }
    }

    return (
        <div className="list-main-container list-main-container__file">
            <div className='list-table list-table__file'>
                <div className="list-table-row list-table-row__file">
                    <input type="checkbox" id="select-all" checked={selectAll} onChange={(e) => handleSelectAllChange(e.target.checked)} />
                    <div className="list-table-cell list-table-cell__file">{t('documentName')}</div>
                    <div className="list-table-cell status-header list-table-cell__file">{t('status')}</div>
                    <div className="list-table-cell list-table-cell__file">{t('uploadDate')}</div>
                </div>
                {[...documentsData].map((document) => (
                    <div className="list-table-row list-table-row__file" key={document.id}>
                        <input type="checkbox" id={`file-select-${document.id}`} onChange={(e) => handleCheckboxChange(document, e.target.checked)} checked={selectedFiles[document.id] || false} />
                        <Link to={`/documents/${document.id}`} className="list-table-cell list-table-cell__file file-name" >{document.name}</Link>
                        <div className="list-table-cell list-table-cell__file file-status-container">
                            <div className='file-status'>{document.status || statusOptions[0]}</div>
                        </div>
                        <div className="list-table-cell list-table-cell__file file-upload-date">{getLocalDateTime(document.created_at, language)}</div>
                    </div>
                ))}
            </div>
            <DocumentsPagination />
        </div>
    )
}
