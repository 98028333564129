import React from 'react';
import { api } from '../../api';
import { baseApi } from '../../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../../styles/Button.scss';
import { useParams } from 'react-router-dom';
import { downloadCsv, mimeType } from '../../downloadCSV';

export default function CSVButton({ setShowDownloadMenu, selectAll, selectedFiles, format = "csv" }) {
    const { inboxData } = useSelector(state => state.inbox);
    const { documentsData } = useSelector(state => state.documents);
    const { inboxId } = useParams();
    const { t } = useTranslation();

    const downloadFile = (blob, fileName) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    };

    const handleDownloadSingleCsv = async () => {
        const fileId = Object.keys(selectedFiles).find(key => selectedFiles[key]);
        if (fileId) {
            const docId = fileId;
            await downloadCsv({
                baseApi,
                documentId: docId,
                documentsData,
                setShowMenu: setShowDownloadMenu,
                t
            });
        }
    };

    const handleDownloadMultipleCsv = async () => {
        let docIds = [];
        for (const fileId in selectedFiles) {
            if (selectedFiles[fileId]) {
                docIds.push(fileId);
            }
        }
        const docIdsQuery = docIds.map(id => `docIds=${id}`).join('&');
        try {
            const response = await api.get(`/inboxes/${inboxId}/extractions?order_by=date:desc&${docIdsQuery}`, {
                headers: {
                    Accept: mimeType[format],
                },
                responseType: "blob",
            });
            const now = new Date();
            const localeString = now.toLocaleString().replace(/:/g, '-').replace(/,/g, '').replace(/\s/g, '_');
            downloadFile(response.data, `extracted_data_${localeString}.${format}`);
        } catch (err) {
            toast.error(t('downloadError', { ns: 'errors' }));
        }
        setShowDownloadMenu(false);
    };

    const handleDownloadAllCSVExtractions = async () => {
        const inbox = inboxData.find(inbox => inbox.id === inboxId);
        const fileName = inbox?.name + '_' + new Date().toISOString().slice(0, 16);
        try {
            const response = await api.get(`/inboxes/${inboxId}/extractions?order_by=date:desc&limit=1000`, {
                headers: {
                    Accept: mimeType[format],
                },
                responseType: "blob",
            });
            downloadFile(response.data, `${fileName}.${format}`);
        } catch (err) {
            toast.error(t('downloadError', { ns: 'errors' }));
        }
        setShowDownloadMenu(false);
    };

    return (
        <>
            <button
                className='dropdown-menu-btn'
                onClick={() => {
                    const selectedFileCount = Object.values(selectedFiles).filter(Boolean).length;
                    selectAll ? handleDownloadAllCSVExtractions() : (selectedFileCount === 1 ? handleDownloadSingleCsv() : handleDownloadMultipleCsv());
                }}>
                {t(`extractionDownloadButton.${format}`)}
            </button>
        </>
    );
}
