import React from 'react'
import { useTranslation } from 'react-i18next';
import '../styles/DangerZoneInbox.scss';
import { useParams } from 'react-router-dom';
import { setSelectedInbox, showInboxDeleteModal } from '../features/modals/modalsSlice';
import { useDispatch } from 'react-redux';



export default function DeleteInbox() {
    const { t } = useTranslation()
    const { inboxId } = useParams();
    const dispatch = useDispatch();
    const handleShowInboxDeleteModal = (inboxId) => {
        dispatch(setSelectedInbox(inboxId));
        dispatch(showInboxDeleteModal());
    }

    return (
        <div className='danger-zone-item-container'>
            <p className='danger-zone-item-title'>{t('deleteThisInbox')}</p>
            <div className='danger-zone-item'>
                <p className='danger-zone-item-text'>{t('deleteInboxText')}</p>
                <button className='btn danger-zone-item-btn' onClick={() => handleShowInboxDeleteModal(inboxId)}>{t('deleteInbox')}</button>
            </div>
        </div>
    )
}
