import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addDocumentToInbox, deleteDocument, fetchAllDocumentsInfo, fetchDocumentExtraction, fetchDocumentInfo, fetchDocumentStatus, fetchFilePreview, fetchOCRExtraction, patchDocumentTags, postDocumentExtraction } from './fileService';
import { documentLimit, getStatusOptions } from '../../config';
import { toast } from 'react-toastify';
import { t } from 'i18next';

const statusOptions = getStatusOptions();

const initialState = {
    isLoadingDocuments: false,
    isLoadingDocumentExtraction: false,
    isLoadingDocumentStatus: false,
    isLoadingDocumentData: false,
    isLoadingDocumentPreview: false,
    isUpdatingDocument: false,
    isUpdatingDocumentStatus: false,
    isUploadingDocument: false,
    isDocumentUploaded: false,
    isSuccessDocument: false,
    isErrorDocument: false,
    isErrorDocumentPreview: false,
    isErrorDocumentExtraction: false,
    messageDoc: '',
    documentsData: [],
    documentPreview: '',
    documentStatus: '',
    documentInfo: {},
    documentOCR: [],
    pageCount: 0,
    previewPage: 0,
    showNoExtraction: false,
    documentExtractionSchema: null,
    documentsCount: 0,
    offset: 0,
    documentsList: [],
    fullDocumentExtraction: null,
    isDeletingDocument: false,
    isDocumentDeleted: false,
    thumbnailState: 'idle',
    thumbnailsData: [],
    currentPreviewPage: null,
    thumbnailsVisible: false,
    boundingBoxesVisible: false,
    isErrorDocumentOCR: false,
    extractedDocId: null,
    calledDocId: null
}

export const getAllDocuments = createAsyncThunk('files/getAllDocuments', async ({ inboxID, offset, orderBy, limit }, thunkAPI) => {
    try {
        const response = await fetchAllDocumentsInfo(inboxID, offset, orderBy, limit);
        const docCount = response.count;
        const documents = await Promise.all(response.results.map(async document => {
            const documentObject = { ...document };
            try {
                const status = await thunkAPI.dispatch(getDocumentStatus(document.id)).unwrap();
                documentObject.status = status;
                documentObject.inboxID = inboxID;
                return documentObject;
            } catch (error) {
                return null;
            }
        }));

        const validDocuments = documents.filter(doc => doc !== null);

        return {
            documentsCount: docCount,
            documents: validDocuments
        }
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})

export const getDocumentExtraction = createAsyncThunk('files/getDocumentExtraction', async ({ docId, recompute }, thunkAPI) => {
    const calledDocId = docId;
    try {
        const response = await fetchDocumentExtraction(docId, recompute);
        const extractedDocId = response.document_id;
        const fullResponse = response.data ? response.data : response.result
        return {
            extractedDocId: extractedDocId,
            fullResponse: fullResponse,
        }
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue({ messageDoc, calledDocId });
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue({ messageDoc, calledDocId });
        }
    }
})

export const getDocumentData = createAsyncThunk('files/getDocumentInfo', async (docId, thunkAPI) => {
    try {
        const response = await fetchDocumentInfo(docId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})

export const getDocumentStatus = createAsyncThunk('files/getDocumentTags', async (docId, thunkAPI) => {
    try {
        const response = await fetchDocumentStatus(docId);
        if (response.count > 0) {
            return response.results[0].value
        } else {
            return statusOptions[0]
        }
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})


export const getDocumentPreview = createAsyncThunk('files/getDocumentPreview', async ({ docId, page }, thunkAPI) => {
    try {
        const response = await fetchFilePreview(docId, page);
        const pageCount = Number(response.headers['x-smartextract-page-count']) || 1;
        return {
            imageUrl: response.imageUrl,
            pageCount: pageCount,
            currentPage: page
        }
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})



export const updateDocumentExtraction = createAsyncThunk('files/updateDocumentExtraction', async ({ docId, newData }, thunkAPI) => {
    try {
        const response = await postDocumentExtraction(docId, newData);
        return response.data;
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})

export const updateDocumentStatus = createAsyncThunk('files/updateDocumentStatus', async ({ docId, status }, thunkAPI) => {
    try {
        const response = await patchDocumentTags(docId, status);
        return response.data;
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);

        }
    }
})

export const uploadDocument = createAsyncThunk('files/uploadDocument', async ({ inboxId, document }, thunkAPI) => {
    try {
        await addDocumentToInbox(inboxId, document);
        return true
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            let toastMessage = (messageDoc === "Not enough credits") ? "insufficientCredit" : "uploadError";
            toast.error(t(toastMessage, { ns: "errors" }), { toastId: "uploadError" });
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})

export const getOCRExtraction = createAsyncThunk('files/getOCRExtraction', async ({ docId, page }, thunkAPI) => {
    try {
        const response = await fetchOCRExtraction(docId, page);
        // const jsonResponse = JSON.stringify(response, null, 4)
        // console.log(jsonResponse);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})

export const removeDocument = createAsyncThunk('files/deleteDoc', async (docId, thunkAPI) => {
    try {
        const response = await deleteDocument(docId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})

export const getThumbnailsPreview = createAsyncThunk('files/getThumbnailsPreview', async ({ docId, page }, thunkAPI) => {
    try {
        const response = await fetchFilePreview(docId, page);
        return response.imageUrl;
    } catch (error) {
        if (error.response.data.detail) {
            const messageDoc = error.response.data.detail
            return thunkAPI.rejectWithValue(messageDoc);
        } else {
            const messageDoc = error.message
            return thunkAPI.rejectWithValue(messageDoc);
        }
    }
})


export const fileSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        resetFiles: (state) => {
            state.isLoadingDocuments = false;
            state.isSuccessDocument = false;
            state.isErrorDocument = false;
            state.messageDoc = '';
        },
        cancelAndResetFiles: (state) => {
            state.isLoadingDocuments = false;
            state.isSuccessDocument = false;
            state.isErrorDocument = false;
            state.messageDoc = '';
            state.pageCount = '';
        },
        resetDocumentsData: (state) => {
            state.documentsData = [];
            state.isLoadingDocuments = false;
            state.isSuccessDocument = false;
            state.isErrorDocument = false;
            state.messageDoc = '';
            state.fullDocumentExtraction = [];
            state.documentPreview = '';
            state.documentStatus = '';
            state.isErrorDocumentExtraction = false;
            state.offset = 0;
        },
        resetDocumentsStates: (state) => {
            state.isLoadingDocuments = false;
            state.isLoadingDocumentExtraction = false;
            state.isLoadingDocumentStatus = false;
            state.isLoadingDocumentData = false;
            state.isLoadingDocumentPreview = false;
            state.isUpdatingDocument = false;
            state.isUpdatingDocumentStatus = false;
            state.isUploadingDocument = false;
            state.isDocumentUploaded = false;
            state.isSuccessDocument = false;
            state.isErrorDocument = false;
            state.isLoadingDocumentExtraction = false;
            state.isErrorDocumentExtraction = false;
            state.messageDoc = '';
        },
        resetDocumentExtraction: (state) => {
            state.fullDocumentExtraction = [];
            state.isLoadingDocumentExtraction = false;
            state.isErrorDocumentExtraction = false;
            state.documentInfo = {};
            state.messageDoc = '';
            state.showNoExtraction = false;
            state.isErrorDocument = false;
        },
        resetDocumentPreview: (state) => {
            state.documentPreview = '';
            state.isLoadingDocumentPreview = false;
            state.isErrorDocumentPreview = false;
            state.pageCount = '';
            state.previewPage = 0;
        },
        resetErrorStates: (state) => {
            state.isErrorDocument = false;
            state.isErrorDocumentPreview = false;
            state.isErrorDocumentExtraction = false;
            state.messageDoc = '';
        },
        resetCalledDocId: (state) => {
            state.calledDocId = '';
        },
        nextPage: (state) => {
            if (state.previewPage < state.pageCount) {
                state.previewPage += 1;
            }
        },
        previousPage: (state) => {
            if (state.previewPage > 0) {
                state.previewPage -= 1;
            }
        },
        handleNext: (state) => {
            state.offset += documentLimit
        },
        handlePrevious: (state) => {
            state.offset -= documentLimit
        },
        resetOffset: (state) => {
            state.offset = 0
        },
        hideThumbnails(state) {
            state.thumbnailsVisible = false;
        },
        showThumbnails(state) {
            state.thumbnailsVisible = true;
        },
        resetPageCount: (state) => {
            state.pageCount = null;
        },
        showBoundingBoxes(state) {
            state.boundingBoxesVisible = true;
        },
        hideBoundingBoxes(state) {
            state.boundingBoxesVisible = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDocuments.pending, (state) => {
                state.isLoadingDocuments = true;
            })
            .addCase(getAllDocuments.fulfilled, (state, action) => {
                state.isLoadingDocuments = false;
                state.isSuccessDocument = true;
                state.documentsData = action.payload.documents;
                state.documentsCount = action.payload.documentsCount
            })
            .addCase(getAllDocuments.rejected, (state, action) => {
                state.isLoadingDocuments = false;
                state.isErrorDocument = true;
                state.messageDoc = action.payload;
            })
            .addCase(getDocumentExtraction.pending, (state) => {
                state.isLoadingDocumentExtraction = true;
                state.isErrorDocumentExtraction = false;
            })
            .addCase(getDocumentExtraction.fulfilled, (state, action) => {
                state.isLoadingDocumentExtraction = false;
                state.isSuccessDocument = true;
                state.fullDocumentExtraction = action.payload.fullResponse;
                state.isErrorDocumentExtraction = false;
                state.extractedDocId = action.payload.extractedDocId;
                if (!action.payload.fullResponse.schema) {
                    state.showNoExtraction = true;
                } else {
                    state.showNoExtraction = false;
                    state.extractionSchema = action.payload.fullResponse.schema;
                }
            })
            .addCase(getDocumentExtraction.rejected, (state, action) => {
                state.isLoadingDocumentExtraction = false;
                state.isErrorDocumentExtraction = true;
                state.messageDoc = action.payload.messageDoc;
                state.fullDocumentExtraction = null;
                state.documentInfo = {};
                state.documentStatus = '';
                state.calledDocId = action.payload.calledDocId;
            })
            .addCase(getDocumentPreview.pending, (state) => {
                state.isLoadingDocumentPreview = true;
            })
            .addCase(getDocumentPreview.fulfilled, (state, action) => {
                state.isLoadingDocumentPreview = false;
                state.isSuccessDocument = true;
                state.documentPreview = action.payload.imageUrl;
                state.pageCount = action.payload.pageCount;
                state.currentPreviewPage = action.payload.currentPage;
            })
            .addCase(getDocumentPreview.rejected, (state, action) => {
                state.isLoadingDocumentPreview = false;
                state.isErrorDocumentPreview = true;
                state.messageDoc = action.payload;
            })
            .addCase(updateDocumentExtraction.pending, (state) => {
                state.isUpdatingDocument = true;
            })
            .addCase(updateDocumentExtraction.fulfilled, (state) => {
                state.isUpdatingDocument = false;
                state.isSuccessDocument = true;

            })
            .addCase(updateDocumentExtraction.rejected, (state, action) => {
                state.isUpdatingDocument = false;
                state.isErrorDocument = true;
                state.messageDoc = action.payload;
            })
            .addCase(getDocumentStatus.pending, (state) => {
                state.isLoadingDocumentStatus = true;
            })
            .addCase(getDocumentStatus.fulfilled, (state, action) => {
                state.isLoadingDocumentStatus = false;
                state.isSuccessDocument = true;
                state.documentStatus = action.payload;
            })
            .addCase(getDocumentStatus.rejected, (state, action) => {
                state.isLoadingDocumentStatus = false;
                state.isErrorDocument = true;
                state.messageDoc = action.payload;
            })
            .addCase(updateDocumentStatus.pending, (state) => {
                state.isUpdatingDocumentStatus = true;
            })
            .addCase(updateDocumentStatus.fulfilled, (state) => {
                state.isUpdatingDocumentStatus = false;
                state.isSuccessDocument = true;
            })
            .addCase(updateDocumentStatus.rejected, (state, action) => {
                state.isUpdatingDocumentStatus = false;
                state.isErrorDocument = true;
                state.messageDoc = action.payload;
            })
            .addCase(uploadDocument.pending, (state) => {
                state.isLoadingDocumentData = true;
            })
            .addCase(uploadDocument.fulfilled, (state) => {
                state.isLoadingDocumentData = false;
                state.isDocumentUploaded = true;
            })
            .addCase(uploadDocument.rejected, (state, action) => {
                state.isLoadingDocumentData = false;
                state.isErrorDocumentExtraction = true;
                state.messageDoc = action.payload;
                state.isDocumentUploaded = false;
            })
            .addCase(getDocumentData.pending, (state) => {
                state.isLoadingDocumentData = true;
            })
            .addCase(getDocumentData.fulfilled, (state, action) => {
                state.isLoadingDocumentData = false;
                state.isSuccessDocument = true;
                state.documentInfo = action.payload;
            })
            .addCase(getDocumentData.rejected, (state, action) => {
                state.isLoadingDocumentData = false;
                state.isErrorDocumentExtraction = true;
                state.messageDoc = action.payload;
            })
            .addCase(getOCRExtraction.pending, (state) => {
                state.isLoadingDocumentData = true;
            })
            .addCase(getOCRExtraction.fulfilled, (state, action) => {
                state.isLoadingDocumentData = false;
                state.isSuccessDocument = true;
                state.documentOCR = action.payload;
                state.isErrorDocumentOCR = false;

            })
            .addCase(getOCRExtraction.rejected, (state, action) => {
                state.isLoadingDocumentData = false;
                state.isErrorDocumentOCR = true;
                state.messageDoc = action.payload;
                state.ocrExtraction = [];
            })
            .addCase(removeDocument.pending, (state) => {
                state.isDeletingDocument = true;
                state.isDocumentDeleted = false;
            })
            .addCase(removeDocument.fulfilled, (state) => {
                state.isDeletingDocument = false;
                state.isDocumentDeleted = true;
            })
            .addCase(removeDocument.rejected, (state, action) => {
                state.isDeletingDocument = false;
                state.isErrorDocument = true;
                state.messageDoc = action.payload;
                state.isDocumentDeleted = false;
            })
            .addCase(getThumbnailsPreview.pending, (state) => {
                state.thumbnailState = 'loading';
            })
            .addCase(getThumbnailsPreview.fulfilled, (state) => {
                state.thumbnailState = 'success';
            })
            .addCase(getThumbnailsPreview.rejected, (state, action) => {
                state.thumbnailState = 'error';
                state.messageDoc = action.payload;
            })
    }
})

export const { resetFiles, updateValidatedData, cancelAndResetFiles, resetDocumentsData, resetDocumentsStates, resetDocumentExtraction, resetDocumentPreview, nextPage, previousPage, handleNext, handlePrevious, resetOffset, hideThumbnails, showThumbnails, resetPageCount, showBoundingBoxes, hideBoundingBoxes, resetErrorStates, resetCalledDocId } = fileSlice.actions;
export default fileSlice.reducer;
