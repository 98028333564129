import axios from 'axios';
import { baseApi } from './config';
import axiosRetry, { retryAfter } from 'axios-retry';

export const api = axios.create({
    baseURL: baseApi,
    withCredentials: true,
    headers: {
        'accept': 'application/json'
    },
});

axiosRetry(api, {
    retries: Infinity,
    retryCondition: (error) => {
        if (axiosRetry.isNetworkError(error)) {
            return true;
        }
        if (error.response) {
            switch (error.response.status) {
                case 409:
                    return true;
                default:
                    return false;
            }
        } else {
            return false;
        }
    },
    retryDelay: (retryCount, error) => {
        if (error.response && error.response.status === 409) {
            return retryAfter(error);
        }
        return 10 * 1000;
    }
});
