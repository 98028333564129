import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteInbox, getEmailAddresses, getInboxDetails, getInboxInfo, resetUpdateInfo } from '../features/inboxes/inboxSlice';
import '../styles/Settings.scss'
import BackButton from './buttons/BackButton';
import { toast } from 'react-toastify';
import APIDocumentation from './APIDocumentation';
import InboxName from './InboxName';
import EmailAddressInSettings from './EmailAddressInSettings';
import InboxIdInSettings from './InboxIdInSettings';
import PipelineIdInSettings from './PipelineIdInSettings';
import DangerZoneInbox from './DangerZoneInbox';
import { hideInboxDeleteModal } from '../features/modals/modalsSlice';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal';
import { resetDocumentsData } from '../features/files/fileSlice';

export default function InboxDetails() {
    const { inboxId } = useParams();
    const { isInfoUpdated, isInboxDeleted, inboxStatus, messageInbox } = useSelector(state => state.inbox);
    const dispatch = useDispatch();
    const { t } = useTranslation('generalSettings')
    const { isInboxDeleteModalOpen } = useSelector(state => state.modals)
    const navigate = useNavigate();


    useEffect(() => {
        if (inboxId) {
            dispatch(getInboxDetails(inboxId))
        }
    }, [inboxId]) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        if (isInfoUpdated) {
            dispatch(getInboxInfo());
            dispatch(getInboxDetails(inboxId))
            dispatch(resetUpdateInfo())
        }
    }, [isInfoUpdated, dispatch, inboxId]);


    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            if (text.includes('@')) {
                toast.success(t('emailCopied', { ns: 'translation' }))
            } else {
                toast.success(t('IDCopied'))
            }
        } catch (err) {
            toast.error(t('copyToClipboardError', { ns: 'errors' }))
        }
    }

    useEffect(() => {
        dispatch(getEmailAddresses(inboxId))
    }, [dispatch, inboxId])

    const handleDeleteInbox = (inboxId) => {
        dispatch(deleteInbox(inboxId))
        dispatch(hideInboxDeleteModal());
    }

    useEffect(() => {
        if (isInboxDeleted) {
            dispatch(resetDocumentsData());
            dispatch(getInboxInfo({ limit: 1000 })).then(() => {
                navigate('/');
            });
        }
    }, [isInboxDeleted]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (inboxStatus === 'error deleting inbox' && messageInbox === "Forbidden") {
            toast.error(t('forbiddenInboxDelete', { ns: 'errors' }), {
                toastId: 'errorDeletingInbox',
            })
        } else if (inboxStatus === 'error deleting inbox') {
            toast.error(t('errorInboxDelete', { ns: 'errors' }), {
                toastId: 'errorDeletingInbox',
            })
        }
    }, [inboxStatus, messageInbox])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className='settings-main-container settings-main-container__inbox'>
            <BackButton />
            <div className='settings-title-container settings-title-container__inbox'>
                <h2 className='settings-title settings-title__inbox'>{t('inboxSettings', { ns: 'translation' })}</h2>
            </div>
            <div className='settings-container settings-container__inbox'>
                <h3 className='settings-subtitle settings-subtitle__inbox'>{t('generalSettings')}</h3>
                <InboxName />
                <EmailAddressInSettings copyToClipboard={copyToClipboard} />
                <div className='divider'></div>
                <APIDocumentation />
                <InboxIdInSettings copyToClipboard={copyToClipboard} />
                <PipelineIdInSettings copyToClipboard={copyToClipboard} />
                <div className='divider'></div>
                <DangerZoneInbox />
            </div>
            {isInboxDeleteModalOpen && <ConfirmDeleteModal handleDelete={() => handleDeleteInbox(inboxId)} />}
        </div>
    )
}
